<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 16:18:02
-->
<template>
    <el-main>
      <h1 class="base-title">测试-扫描</h1>
      <div class="search mt">
        <el-button type="primary" @click="testClick">搜索</el-button>
      </div>  
    </el-main>

    
</template>

<script>
import {httpPost,httpGet} from "@/utils/http"
import global from '@/common/global'
import wx from "weixin-js-sdk"

export default {
  name: 'testScan',
  data(){
    return {
      searchVal: '',
      suiteAccessToken:'',
      corpToken: '',
      urls:{
        getSuiteAccessTokenUrl : '/ewbid/workweixin/getSuiteAccessToken',
        getSignatureUrl : '/ewbid/workweixin/signature',
        getCorpTokenUrl: '/ewbid/workweixin/getCorpToken'
      }
    }
  },

  methods: {
      testClick() {

       
        //获取suite access token
        // this.code = tempCode
        //从后台获取corp_token
        let url = global.gateway+this.urls.getCorpTokenUrl
        let params = {
          "suiteId":global.suiteId,
          "corpId":global.corpId
        }			
        httpGet(url,params).then(res=>{
          if(res.status=="200"&&res.data.retCode=="0"){
            // alert(res.data.access_token);
            this.corpToken = res.data.access_token
            //获取ticket
            let params = {
              access_token: this.corpToken,
              // type: 'agent_config'
            }
            httpGet("/workweixinauth/cgi-bin/get_jsapi_ticket",params).then(res1=>{
              // alert(JSON.stringify(res1))
              if(res1.data.errmsg == "ok"){ 
                // noncestr（随机字符串）,微信开发文档中为明确说明字符串位数
                let randomStr = global.randomStr(6)   
                let nowDate = new Date().getTime() 
                let jsapi_ticket =
                  "jsapi_ticket=" +
                  res1.data.ticket +
                  "&noncestr=" +
                  randomStr +
                  "&timestamp=" +
                  nowDate +
                  "&url=" +
                  window.location.href;
                //参数
                let dataObj = {
                  "ticket": jsapi_ticket
                }        
                let signature = "";
                httpPost(global.gateway+this.urls.getSignatureUrl,dataObj).then(res2=>{
                   
                  if(res2.data.success){
                    signature = res2.data.result.signature;
                    wx.config({
                          beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                          appId: global.corpId, // 必填，企业微信的corpID
                          timestamp: nowDate, // 必填，生成签名的时间戳
                          nonceStr: randomStr, // 必填，生成签名的随机串
                          signature: signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                          jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                      });
                    wx.ready(function(){
                        wx.scanQRCode({
                            desc: 'scanQRCode desc',
                            needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
                            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
                            success: function(res) {
                                // 回调
                                alert(JSON.stringify(res))
                                // alert("回调");
                            },
                            error: function(res) {
                                if (res.errMsg.indexOf('function_not_exist') > 0) {
                                    alert('版本过低请升级')
                                }
                            }
                        });

                    });
                    wx.error(function(res11){
                      alert(JSON.stringify(res11.err_msg));
                    });
                    // wx.checkJsApi({
                    //     jsApiList: ['scanQRCode'], // 需要检测的JS接口列表
                    //     success: function(res3) {
                    //       alert(2);
                    //         // 以键值对的形式返回，可用的api值true，不可用为false
                    //         // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                    //     },
                    //     fail: function(res3) {
                    //       alert(3);
                    //       if(res3.errMsg.indexOf('function not exist') > -1){
                    //           alert('版本过低请升级')
                    //       }
                    //     }
                    // }); 

                  }else{
                    this.$message({
                      type: 'error',
                      message: res2.data.message
                    }) 
                    return;
                  }
                }); 

              }
            });
  
          }else{
            this.$message({
              type: 'error',
              message: res.data.errmsg?res.data.errmsg:''
            })             
          }
        }).catch(error=>{
          console.log(error)
        })   

     
      },

    }
}
</script>
<style lang="scss" scoped>
.btn { padding: 12px }
</style>
